import React, { useEffect, useState } from 'react'
import classes from './styles.module.css'

const CountDown = ({ date, asset }: any): any => {
  const [state, setState] = useState({
    d: 0,
    h: 0,
    m: 0,
    s: 0
  })

  useEffect(() => {
    let c = new Date()
    let n = new Date(date)

    const id = setInterval(() => {
      c = new Date()
      n = new Date(date)
      let d = Math.floor((n.getTime() - c.getTime()) / (1000 * 60 * 60 * 24))
      let h = Math.floor(((n.getTime() - c.getTime()) / (1000 * 60 * 60)) % 24)
      let m = Math.floor(((n.getTime() - c.getTime()) / (1000 * 60)) % 60)
      let s = Math.floor(((n.getTime() - c.getTime()) / 1000) % 60)
      setState({ d, h, m, s })
    }, 1000)

    if (n.getTime() - c.getTime() <= 0) clearInterval(id)
    return () => clearInterval(id)
  })

  const { d, h, m, s } = state

  const getLink = () => {
    let link = ''
    try {
      link = asset.permalink
    } catch (error) {
      link = '/'
      console.log('null found')
    }
    return link;
  }

  return (
    <>
      {new Date(date).getTime() - Date.now() <= 0 ? (
        <a href={getLink()} target="_blank" rel="noreferrer">
          <div className={classes.buy}>Buy now</div>
        </a>
      ) : (
        <div>
          <div className={classes.dropsTitle}>Drops in</div>
          <div className={classes.dropsDate}>{d}d {h}h {m}m {s}s</div>
        </div>
      )}
    </>
  )
}

export default CountDown

import styles from './styles.module.css';
import React, { useState, useEffect, Fragment } from 'react';
import Logo from '../../assets/svg/logo.svg';
import Musi from '../../assets/images/musi.png';
import Swap from '../../assets/images/swap.png';

const WelcomeScreen = ({ children }) => {

  const [show, setScreen] = useState(false);
  const [mount, setMount] = useState(false);
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDelay(false);
    }, 4000);
    setMount(true);
    setScreen(window.sessionStorage.showWelcomeScreen);
    window.sessionStorage.showWelcomeScreen = "false";
  }, []);

  return (
    <Fragment>
      {
        mount ?
          <Fragment>
            {
              show !== "false" && delay
                ?
                <div className={`${styles.container}`}>
                  <div className={styles.wrapper}>
                    <img className={`${styles.text} ${styles.left}`} src={Musi} alt="" />
                    <img className={styles.logo} src={Logo} alt="" />
                    <img className={`${styles.text} ${styles.right}`} src={Swap} alt="" />
                  </div>
                </div>
                : <>{children}</>
            }
          </Fragment>
          : null
      }
    </Fragment>
  )

}

export default WelcomeScreen;
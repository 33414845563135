// import "./onHoverStyle.css";
import { Scrollbars } from 'react-custom-scrollbars';
import { DropdownButton } from 'react-bootstrap'
// import './QuantityInput.css'
import { CurrencyAmount, JSBI, Token, Trade } from 'uniswap-polygon-sdk'
import logo from './logo.png';
import coinye from './coinye.png';
import React, { Component, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
// import BannerImg from '../../assets/images/BillieHero.png'
import  '../../styles.css'

// import {ScrollBox, ScrollAxes, FastTrack} from 'react-scroll-box'; // ES6
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
// import AppBody from '../AppBody'
// import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { relative } from 'path';
import Jukebox from '../../assets/images/Jukebox.svg'

export default function Banner() {
  let title= "Collectibles";
  let subtitle = "The first ever musician exchange. Support your favorite artist with artist tokens and get rewarded exclusive benefits. Curate groups of artists and get incentivized for it.";

    
  return (
    <>
    <div className="bannerHero">
    {/* <img className="bannerImg" src={BannerImg}/> */}
    <h1 className="bannerTitle">{title}</h1>
    <h2 className="subtitle">{subtitle}</h2>
    </div>



    

    </>
  )
}

import styles from './form.module.css';
import React, { useState, useEffect } from 'react';

const Form = ({ edit, song, setUploaded }: any) => {
  const [form, setForm] = useState({});
  const { artist_name, song_title, genre, description, cost, released_date }: any = form;

  useEffect(() => {
    setForm(song);
  }, []);

  const handleChange = (event: any) => {
    const { target: { name } } = event;
    console.log(name)
    if (name === "song_file" || name === "artwork") {
      const { target: { files } } = event;
      setForm({ ...form, [name]: files[0] })
    } else {
      const { target: { value } } = event;
      setForm({ ...form, [name]: value })
    }
  }

  const validateForm = () => {
    for (let key in form) {
      let newForm: any = {...form};
      if (!newForm[key]) {
        alert(`${key} is required`)
        return false
      }
    }
    return true
  }

  // I temporary store uploaded files in the session storage for demonstration but should be uploaded to the ipfs
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!validateForm()) return;
    if (window.sessionStorage.form) {
      let formData: any = window.sessionStorage.getItem("form");
      let store = JSON.parse(formData);
      window.sessionStorage.setItem("form", JSON.stringify([...store, form]))
    } else {
      window.sessionStorage.setItem("form", JSON.stringify([form]))
    }
    setUploaded(true)
  }

  const handleSave = () => {
    if (!validateForm()) return;
    console.log('...saving file')
  }

  const handleDelete = () => {
    console.log('...deleting file')
  }

  const getDate = () => {
    const d = new Date(released_date);
    return d.toLocaleDateString();
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.formInputWrapper}>
        <label htmlFor="artist_name">Artist</label>
        <input
          onChange={handleChange}
          value={artist_name}
          name="artist_name"
          type="text"
          placeholder="firstname lastname"
        />
      </div>
      <div className={styles.formInputWrapper}>
        <label htmlFor="title">Song Title</label>
        <input
          onChange={handleChange}
          value={song_title}
          name="song_title"
          type="text"
        />
      </div>
      <div className={styles.formInputWrapper}>
        <label htmlFor="genre">Genre</label>
        <input
          onChange={handleChange}
          value={genre}
          name="genre"
          type="text"
        />
      </div>
      <div className={styles.formInputWrapper}>
        <label htmlFor="description">Description</label>
        <input
          onChange={handleChange}
          value={description}
          name="description"
          type="text"
        />
      </div>
      <div className={styles.formInputWrapper}>
        <label htmlFor="const">Cost per play</label>
        <input
          onChange={handleChange}
          value={cost}
          name="cost"
          type="number"
          min="0"
        />
      </div>
      <div className={styles.formInputWrapper}>
        <label htmlFor="song_file">Song</label>
        <input
          onChange={handleChange}
          name="song_file"
          type="file"
          accept="audio/*"
        />
      </div>
      <div className={styles.formInputWrapper}>
        <label htmlFor="artwork">Album Artwork</label>
        <input
          onChange={handleChange}
          name="artwork"
          type="file"
          accept="image/*"
        />
      </div>
      <div className={`${styles.dateWrapper} ${edit && styles.show}`}>
        <label htmlFor="released_date">Date Released</label>
        <div>{getDate()}</div>
      </div>

      <div className={`${styles.editBtn} ${edit && styles.show}`}>
        <button onClick={handleDelete} type="button" className={styles.delete} >Delete Song</button>
        <button onClick={handleSave} type="button" className={styles.save}>Save</button>
      </div>
      <div className={`${styles.submitBtn} ${!edit && styles.show}`}>
        <button type="submit" className={styles.submit}>submit</button>
      </div>
    </form>
  )
}

export default Form;
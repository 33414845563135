import { CurrencyAmount, JSBI, Token, Trade } from 'uniswap-polygon-sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { BlueCard, LightCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import Collection from '../../components/Collections'
import Sidebar from '../../components/Sidebar'
import Carousel from '../../components/Carousel';
import { ethers } from 'ethers';
import Abi from './contractAbi.json';
import { result } from 'lodash'

export default function CreateToken() {

  const CreatorAddress = "0x00d4ed6200d714defa57f175e5cad5f1ee5987d0";
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')


  async function Mint() {
    if (typeof window.ethereum !== 'undefined') {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      
      const accounts = await (window as any).ethereum.request({
        method: 'eth_requestAccounts'
      })
      console.log(accounts)
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CreatorAddress, Abi, signer);
      try {
        await contract.createToken(name, symbol, accounts[0]);
        contract.on("Created", (user, tokenAddress) => {
          alert(`your token has been deployed, here is your token address ${tokenAddress}`)
        })
      } catch (error) {
        console.log(error)
      }
    } 
    
  }

  const handleName = (evt: any) => {
    evt.preventDefault();
    setName(evt.target.value);
    console.log(name);
  }
  const handleSymbol = (evt: any) => {
    evt.preventDefault();
    setSymbol(evt.target.value);
    console.log(symbol);
  }



  return (
    <>
    <div className='tw-flex tw-items-center tw-h-full tw-justify-center tw-p-0 tw-w-3/6 tw-text-blue-900'>
      <form className="tw-flex gap-36 tw-flex-col tw-p-0 tw-items-center tw-justify-center tw-bg-white tw-shadow-md tw-rounded tw-h-1/2 tw-w-full tw-max-w-lg tw-mx-auto sm:tw-max-w-xl">
        <div className="tw-m-4">
          <label
            className="tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="tw-bg-white tw-focus:outline-none tw-focus:shadow-outline tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-4 tw-block tw-w-full appearance-none leading-normal"
            id="name"
            value={name}
            placeholder="jane@example.com"
            onChange={handleName}
          />
        </div>
        <div className="tw-m-4">
          <label
            className="tw-text-gray-700 tw-text-sm tw-font-bold mb-2"
            htmlFor="symbol"
          >
            Symbol
          </label>
          <input
            className="tw-bg-white tw-focus:outline-none tw-focus:shadow-outline tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-4 tw-block tw-w-full appearance-none leading-normal"
            id="password"
            value={symbol}
            onChange={handleSymbol}
          />
        </div>
        <button onClick={Mint} type="button" className="tw-h-12 tw-px-6 tw-m-2 tw-text-lg tw-text-indigo-100 tw-transition-colors tw-duration-150 tw-bg-indigo-700 tw-rounded-lg focus:tw-shadow-outline hover:tw-bg-indigo-800">Create Token</button>
      </form>
    </div>
    </>
  )
}

import React, { Suspense, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import background from '../assets/images/pepe-bg.png'
import Banner from '../components/Banner'
import CommingSoon from '../components/comming-soon'
import WelcomeScreen from '../components/welcome-screen'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Earn from './Earn'
import Manage from './Earn/Manage'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import NFT from './NFT'
import Jukebox from './Jukebox'
import Charts from './Charts'
import Tickets from './Tickets'
import Merch from './Merch'
import Collectible from './Collectible'
import Contact from './Contact'
import Pitch from './Pitch'
import Music from './Music'
import Upload from './MusicUpload'
import CreateToken from './Token'

import Landing from './Landing'
import Sidebar from '../components/Sidebar/index'
import DropZone from './DropZone'
import '../index.scss'
// import Contact from './Contact'

import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

import Vote from './Vote'
import VotePage from './Vote/VotePage'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  background: #151616;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
`
const Marginer = styled.div`
  margin-top: 0rem;
`
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1em;
  @media (max-width: 960px) {
    padding-bottom: 6em;
  }
`
function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  const location = useLocation()
  const [scroll, setScroll] = useState<any>();

  const handleScroll = () => {
    let body = document.querySelector("#body");
    let totalHeight: any = body?.scrollHeight;
    let scrollHeight: any = body?.scrollTop;
    let clientHeight: any = body?.clientHeight;
    let height = totalHeight - clientHeight;
    let scrolled = (scrollHeight/height) * 100;

    setScroll(scrolled)
  }

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <WelcomeScreen>
        <CommingSoon location={location.pathname} ignore={['/']} />
        <AppWrapper>
          <URLWarning />
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper id="body" onScroll={handleScroll}>
            <Popups />
            <Polling />
            <TopLevelModals />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/" render={()=> <Landing scroll={scroll}/>} />

                <Route exact strict path="/swap" component={Swap} />

                <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/uni" component={Earn} />
                <Route exact strict path="/vote" component={Vote} />
                <Route exact strict path="/jukebox" component={Jukebox} />
                <Route exact strict path="/music" component={Music} />
                <Route exact strict path="/form" component={Upload} />
                <Route exact strict path="/merch" component={Merch} />
                <Route exact strict path="/collections" component={Collectible} />
                <Route exact strict path="/create-artist-token" component={CreateToken} />

                <Route exact strict path="/tickets" component={Tickets} />
                <Route exact strict path="/test" component={Banner} />
                <Route exact strict path="/contact" component={Contact} />
                <Route exact strict path="/pitch" component={Pitch} />

                <Route exact strict path="/nft" component={NFT} />
                <Route exact strict path="/charts" component={Charts} />
                <Route exact strict path="/dropzone" component={DropZone} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact path="/create" component={AddLiquidity} />
                <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />
                <Route exact strict path="/vote/:id" component={VotePage} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        </AppWrapper>
      </WelcomeScreen>
    </Suspense>
  )
}

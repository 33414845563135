import React from 'react';
import styles from './styles.module.css';
import Web1 from '../../assets/images/web1image.png';
import Web2 from '../../assets/images/web2image.png';
import Web3 from '../../assets/images/web3image.png';
import Arrow from '../../assets/images/arrow-point.png';
import Musiswap from '../../assets/images/MUSISWAP.png';

const Tag = ({ children }: any) => (
    <div className={styles.tag}>{children}</div>
)

const InternetExploration = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.header}>Evolution of the Internet</div>
                <div className={styles.innerWrapper}>
                    <div className={styles.web1}>
                        <Tag>Read</Tag>
                        <img className={styles.image} src={Web1} alt="" />
                        <div className={styles.content}>
                            <div className={styles.header}>Web 1</div>
                            <div>content delivery networks, static pages, frames + tables, company focused, owning content</div>
                            <div className={styles.subHeading}>Examples</div>
                            <div>Personal Sites, Portals, Web Forms, Directories </div>
                        </div>
                        <img className={styles.arrow} src={Arrow} alt="" />
                    </div>
                    <div className={styles.web2}>
                        <Tag>Read + Write</Tag>
                        <img className={styles.image} src={Web2} alt="" />
                        <div className={styles.content}>
                            <div className={styles.header}>Web 2</div>
                            <div>community focus, tagging, blogs/wikies, sharing content, web applications, wikipedia, xml/rss, user generated</div>
                            <div className={styles.subHeading}>Examples</div>
                            <div>Facebook, Youtube, Itunes, Google, Ebay, Amazon, Spotify, Skype, Twitter</div>
                        </div>
                        <img className={styles.arrow} src={Arrow} alt="" />
                    </div>
                </div>
            </div>
            <div className={styles.web3Wrapper}>
                <img className={styles.image} src={Musiswap} alt="" />
                <div className={styles.web3}>
                    <Tag>Read + Write + Trust</Tag>
                    <img className={styles.img} src={Web3} alt="" />
                    <div className={styles.content}>
                        <div className={styles.header}>Web 3</div>
                        <div>sharing economy, cryptocurrencies, blockchains, tokens, NFTs, p2p, smart contracts, dApps, consensus protocols, decentralized, autonomous, DIDs</div>
                        <div className={styles.subHeading}>Examples</div>
                        <div>Bitcoin, Uniswap, Yearn, Opensea, Decentraland, Launchpool, Metamask, IPFS, Brave Browser</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InternetExploration;

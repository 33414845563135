import styles from './upload.module.css';
import React, { useState } from 'react';
import Form from '../../components/MusicUpload/form/form';
import Preview from '../../components/MusicUpload/preview/preview';
import Manage from '../../components/MusicUpload/manage/manage';

const Upload = () => {
  const [upLoaded, setUploaded] = useState(false);

  const song = {
    artist_name: "",
    song_title: "",
    genre: "",
    description: "",
    cost: 0,
    song_file: {},
    artwork: {},
    released_date: Date.now()
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1><span className={styles.accent}>Juke</span> box</h1>
        <p>Play artist exclusive songs</p>
      </div>

      {
        !upLoaded
          ?
          <div className={styles.formWrapper}>
            <h1 className={styles.formHeading}>Welcome <span className={styles.accent}>User's Name</span>!</h1>
            <div className={styles.title}>
              <h3>Upload new song</h3>
              <div className={styles.underline} />
            </div>
            <p className={styles.description}>Upload your musiswap exclusive song to the jukebox.</p>
            <div className={styles.formContainer}>
              <h2>Upload</h2>
              <Form edit={false} song={song} setUploaded={setUploaded}/>
            </div>
            <div className={styles.manageWrapper}>
              <div className={styles.title}>
                <h3>manage music library</h3>
                <div className={styles.underline} />
              </div>
              <p className={styles.description}>view and manage your exclusive music previously Uploaded to the jukebox. Click on the song to see dropdown menu</p>
                <Manage/>
            </div>
          </div>
          :
          <div className={styles.previewWrapper}>
            <h1 className={styles.previewHeading}>Success!</h1>
            <p className={styles.description}>Your song has been Uploaded. Preview below.</p>
            <Preview setUploaded={setUploaded}/>
          </div>
      }
    </div>
  )
}

export default Upload;
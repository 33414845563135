import styles from './player.module.css';
import Play from '../../../assets/music/play-button.svg';
import Pause from '../../../assets/music/pause-button.svg';
import ArrowLeft from '../../../assets/music/arrow-left.svg';
import ArrowRight from '../../../assets/music/arrow-right.svg';
import VerifiedIcon from '../../../assets/music/verified-icon.png';
import React, { useRef, useState, useEffect } from 'react';
import { formatTime } from '../utils';

const Player = ({ activeSong, music, setActiveSong, setFilter }) => {

  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("00:00");
  const [play, setPlay] = useState(true);
  const [value, setValue] = useState("0");
  const [max, setMax] = useState(null);

  const { artwork, artist_name, exclusive, song_file } = activeSong;
  const audioRef = useRef(null);

  const handleClick = () => {
    let audio = audioRef.current;

    if (audio.paused || audio.ended) {
      audio.play();
      setPlay(false)
    } else {
      audio.pause();
      setPlay(true)
    }

    audio.onended = () => {
      setPlay(true);
      audio.currentTime = 0;
    }

    audio.onemptied = () => {
      setPlay(true)
    }

    audio.ontimeupdate = () => {
      setCurrentTime(formatTime(audio.currentTime));
      setValue(audio.currentTime)
    }

    audio.onplay = () => {
      let duration = audio.duration === Infinity ? 0 : audio.duration;
      setDuration(formatTime(duration))
    }
  }

  const handleChange = (e) => {
    setValue(audioRef.current.currentTime = e.target.value)
  }

  const handleLeft = () => {
    let container = [...music];
    container.push(container.shift());
    setFilter(container)
    setActiveSong(container[0])
  }

  const handleRight = () => {
    let container = [...music];
    container.unshift(container.pop());
    setFilter(container)
    setActiveSong(container[0])
  }

  useEffect(() => {
    let audio = audioRef.current;
    audio.onloadeddata = e => {
      let duration = audio.duration === Infinity ? 0 : audio.duration;
      setDuration(formatTime(duration));
      setMax(duration);
      setValue(audio.currentTime)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.play}>
        <div className={styles.base}>
          <div className={styles.base_} />
          <img className={styles.player} src={artwork} alt="" />
        </div>
        <div className={styles.arrow}>
          <div onClick={handleLeft} className={styles.left}>
            <img src={ArrowLeft} alt="" />
          </div>
          <div onClick={handleRight} className={styles.right}>
            <img src={ArrowRight} alt="" />
          </div>
        </div>

      </div>

      <audio style={{ display: 'none' }} ref={audioRef} src={song_file}></audio>

      <div className={styles.control}>
        {
          play
            ? <img onClick={handleClick} src={Play} alt="" />
            : <img onClick={handleClick} src={Pause} alt="" />
        }
      </div>

      <div className={styles.level}>
        <div className={styles.time}>{currentTime}/{duration}</div>
        <input
          onChange={handleChange}
          value={value}
          type="range"
          min="0"
          max={max}
          className={styles.levelInner}
        />

      </div>
      <div className={styles.artist}>
        <h1>Preview of THIS MY DROP</h1>
        <div className={styles.name}>
          <p>by {artist_name}</p>
          {
            exclusive === "true" && <img className={styles.verified} src={VerifiedIcon} alt="" />
          }
        </div>
      </div>
    </div>
  )
}

export default Player;
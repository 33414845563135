import React, { useState, useEffect } from 'react'
// import Countdown from 'react-countdown'
import classes from './styles.module.css'
import ChevronLeft from '../../assets/images/chevron-left.png'
import ChevronRight from '../../assets/images/chevron-right.png'
import CountDown from '../CountDown';

const ImageSlider = (props: any) => {
  const { assets } = props
  const [images, setImages] = useState<string[]>([])
  const [view, setView] = useState<string[]>([])
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [animate, setAnimate] = useState<boolean>(false)
  const [dir, setDir] = useState<boolean>(false)
  const [isImage, setIsImage] = useState<boolean>(true)

  const preview: number = 3
  const maxCount: number = Math.ceil(images.length - preview)

  const handleAnimate = () => {
    let img = null
    img = document.querySelector('#img1')
    const i = img as HTMLElement
    setAnimate(true)
    i.onanimationend = () => {
      setAnimate(false)
    }
  }

  const handleRight = () => {
    if (count === maxCount) return
    setCount(c => (c < maxCount ? c + 1 : c))
    handleAnimate()
    setDir(true)
  }

  const handleLeft = () => {
    if (count === 0) return
    setCount(c => (c > 1 ? c - 1 : 0))
    handleAnimate()
    setDir(false)
  }

  useEffect(() => {
    if (assets) {
      setIsImage(true)
      setImages([null, ...assets, null].flat())
    } else {
      setIsImage(false)
    }
  }, [assets])

  useEffect(() => {
    setStart(count + preview - preview)
    setEnd(count + preview)
    let v = images.slice(start, end)
    setView(v)
  }, [start, end, count, images])

  return (
    <div className={classes.display}>
      {isImage ? (
        <>
          <div className={classes.images}>
            {view &&
              view.map((asset: any, index) => (
                <div
                  id={`img${index}`}
                  key={index}
                  className={`${classes.imageWrapper} ${asset === null ? classes.hide : classes.show} ${
                    classes[`_${index}`]
                  } ${!animate ? null : dir ? classes.animateRight : classes.animateLeft}`}
                >
                  <div className={classes.imageContainer}>
                    <div className={classes.name}>{asset && asset.name}</div>
                    {asset && <img className={classes.image} src={asset.image_url} alt="" />}
                    <div>
                      <CountDown date = {`2021 10 31`} asset={asset}/>
                    </div>
                  </div>
                </div>
              ))}
            <div className={classes.arrows}>
              <div className={`${classes.arrowLeft} ${count === 0 && classes.inactive}`} onClick={handleLeft}>
                <img src={ChevronLeft} alt="" />
              </div>
              <div className={`${classes.arrowRight} ${count === maxCount && classes.inactive}`} onClick={handleRight}>
                <img src={ChevronRight} alt="" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.noImage}>No image to show</div>
      )}
    </div>
  )
}

export default ImageSlider



import ReactSlidy from 'react-slidy'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js';
import { ethers } from 'ethers';
import Image from '../../assets/images/Collectible-image.png'
import DropsImage from '../../assets/images/drops-image.png'
import artistImage from '../../assets/images/artist-image.png'
import collabImage from '../../assets/images/collab-image.png'
import 'react-slidy/lib/index.scss'

const SLIDES = [Image, DropsImage, artistImage, collabImage]

const createStyles = isActive => ({
  background: 'transparent',
  border: 0,
  color: isActive ? '#333' : '#ccc',
  cursor: 'pointer',
  fontSize: '40px'
})

const Carousel = () => {
    const [actualSlide, setActualSlide] = useState(0)
    const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  const updateSlide = ({currentSlide}) => {
    setActualSlide(currentSlide)
  }


  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setInterval(
      () =>
      setActualSlide((prevIndex) =>
          prevIndex === SLIDES.length - 1 ? 0 : prevIndex + 1
        ),
      3000
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

   
      return (
        <div>
        <ReactSlidy doAfterSlide={updateSlide} slide={actualSlide}>
          {SLIDES?.map(src => (
            <img alt="" key={src} src={src} />
          ))}
        </ReactSlidy>
        <div className="Dots tw-w-full tw-align-center tw-self-center">
        <div className="tw-flex tw-flex-row tw-w-full tw-justify-center">
          {SLIDES?.map((_, index) => {
            return (
              <button
                key={index}
                style={createStyles(index === actualSlide)}
                onClick={() => updateSlide({currentSlide: index})}
              >
                &bull;
              </button>
            )
          })}
        </div>
        </div>
      </div>
      )

}

export default Carousel;
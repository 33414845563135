import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js';
import { ethers } from 'ethers';
import  '../../styles.css'

const Collections = () => {
    const provider = new ethers.providers.JsonRpcProvider()
    const [collections, setCollections] = useState([]);
    const [assets, setAssets] = useState([]);
    const seaport = new OpenSeaPort(provider, {
      networkName: Network.Main
    })
    let noOfNFTsToDisplay = 12;
    console.log(collections)
    const fetchAsset = async () => {
        const asset = {
      tokenAddress: "0x06012c8cf97bead5deae237070f9587f8e7a266d", // CryptoKitties
      tokenId: "", // Token ID
      }
      const openSeaAsset = await seaport.api.getAsset(asset)
      console.log(openSeaAsset)
      }
      
      fetchAsset()
      
    useEffect(()=>{
        axios.get(`https://api.opensea.io/api/v1/assets?owner=0x6e3cd6b1d5311afeb1357d69feecfd6f0ce54e18&order_direction=desc&offset=0&limit=${noOfNFTsToDisplay}`).then((res)=>{
            setAssets(res.data)
        })
      }, [])

      return (
        <div className='tw-grid tw-grid-cols-4 tw-gap-4 tw-p-10 tw-my-10 md:tw-grid-cols-1'>
        {assets?.assets?.length !== 0 && assets?.assets?.map((each)=>(
            <div key={each.id} className="nftCard tw-card tw-bordered tw-border-white tw-w-full tw-h-9/12">
              <img src={each.image_url} className="tw-w-full tw-object-cover" />
            <div className="tw-card-body">
              <h2 className="tw-card-title tw-text-white">{each.name}
                <div className="tw-badge tw-mx-2 tw-badge-secondary">NEW</div>
              </h2> 
              <p>{each.description ? each.description : 'This NFT Item has no Description'}</p> 
              <div className="tw-justify-end tw-card-actions tw-flex-row">
                <a target="_blank" rel="noreferrer" href={each.permalink}><button className="openSeaBuy tw-btn tw-btn-secondary">Buy</button></a>
                {/* <a target="_blank" rel="noreferrer" href={each.permalink}><button className="openSeaBuy tw-btn tw-btn-secondary">OpenSea</button></a> */}
              </div>
            </div>
          </div> 
        ))}
      </div>
      )

}

export default Collections;
import styles from './sort.module.css';
import { useState } from 'react';
import SelectedIcon from '../../../assets/music/selected-icon.svg';
import DropdownIcon from '../../../assets/music/dropdown-icon.svg';
import React from 'react';

const Sort = ({ onSort }) => {
  const [sort, setSort] = useState(false);
  const [activeSort, setActiveSort] = useState('sort');

  const handleSort = () => {
    setSort(!sort)
  }

  const handleActiveSort = (value, label) => {
    setActiveSort(label)
    setSort(false)
    onSort(value)
  }

  return (
    <div className={styles.sort}>
      <div onClick={handleSort} className={styles.activeSort}>{activeSort}<img className={styles.dropIcon} src={DropdownIcon} alt="" /></div>
      <ul className={`${styles.sortDropdown} ${sort && styles.dropdown}`}>
        <li onClick={(() => handleActiveSort("exclusive", "Popularity"))}><div>Popularity</div>
          {
            activeSort === "Popularity" && (
              <img className={styles.active} src={SelectedIcon} alt="" />
            )
          }
        </li>
        <li onClick={(() => handleActiveSort("genre", "Genre"))}><div>Genre</div>
          {
            activeSort === "Genre" && (
              <img className={styles.active} src={SelectedIcon} alt="" />
            )
          }
        </li>
        <li onClick={(() => handleActiveSort("release_date", "Recently added"))}><div>Recently added</div>
          {
            activeSort === "Recently added" && (
              <img className={styles.active} src={SelectedIcon} alt="" />
            )
          }
        </li>
        <li onClick={(() => handleActiveSort("cost", "Cost"))}><div>Cost</div>
          {
            activeSort === "Cost" && (
              <img className={styles.active} src={SelectedIcon} alt="" />
            )
          }
        </li>
        <li onClick={(() => handleActiveSort("views", "Plays"))}><div>Plays</div>
          {
            activeSort === "Plays" && (
              <img className={styles.active} src={SelectedIcon} alt="" />
            )
          }
        </li>
      </ul>
    </div>
  )
}

export default Sort;
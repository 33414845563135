export const formatTime = seconds => {
  seconds = Math.round(seconds);
  let minutes = 0;
  if (seconds >= 60) {
    minutes = Math.floor(seconds / 60);
    seconds = (seconds - (minutes * 60));
  }

  seconds = seconds + "";
  if (seconds.length === 1) {
    seconds = "0" + seconds
  }

  return minutes + ":" + seconds;
}

import Form from '../form/form';
import styles from './manage.module.css';
import React, { useState, useEffect } from 'react';

const Manage = () => {
  const [show, setShow] = useState(-1);
  const [musicList, setMusicList] = useState([]);

  const handleClick = (index: any) => {
    if (show === index) return setShow(-1);
    setShow(index);
  }

  // for demostration, this is also retrieved from session storage but should come from the ipfs
  useEffect(() => {
    let formData: any = window.sessionStorage.getItem("form");
    let store = JSON.parse(formData);
    setMusicList(store);
  }, [])

  return (
    <div className={styles.container}>
      {
        musicList && musicList
          .map((song: any, index) => (
            <div key={index} className={styles.songWrapper}>
              <div onClick={() => handleClick(index)} className={`${styles.song} ${show === index && styles.show }`}>
                <div className={styles.left}>
                  <div>{song.song_title}</div>
                  <div className={styles.description}>({song.description})</div>
                </div>
                <div className={styles.right}>
                  <div>{song.cost} ${(song.artist_name).split(' ')[1]}</div>
                </div>
              </div>
              {
                show === index && <Form edit={true} song={song}/>
              }
            </div>
          ))
      }
    </div>
  )
}

export default Manage
import styles from './play-button.module.css';
import React from 'react';

const PlayButton = ({ activeSong }) => {
  const { artist_name, cost, song_file, } = activeSong;

  const handleClick = () => {
    let audio = new Audio();
    audio.src = song_file;
    audio.load();
    audio.play();
  }

  return (
    <div onClick={handleClick} className={styles.container}>INSERT {cost} ${artist_name} TO PLAY </div>
  )
}

export default PlayButton
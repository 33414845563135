import Nft from '../../components/Nftcard'
import classes from './styles.module.css'
import ImageSlider from '../../components/ImageSlider'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Banner from '../../assets/images/dropzone-bg.png'
import Musiswap from '../../assets/images/MUSISWAP.svg'

const DropZone = () => {
  const [assets, setAssets] = useState<any>()

  const noOfNFTsToDisplay = 6
  const url = `https://api.opensea.io/api/v1/assets?owner=0x6e3cd6b1d5311afeb1357d69feecfd6f0ce54e18&order_direction=desc&offset=0&limit=${noOfNFTsToDisplay}`

  useEffect(() => {
    axios.get(url).then((res: any) => {
      setAssets(res.data.assets)
      console.log(res.data.assets)
    })
  }, [url])

  return (
    <div className={classes.container}>
      <div className={classes.banner} style={{ backgroundImage: `url(${Banner})` }}>
        <div className={classes.bannerContent}>
          <img className={classes.musiswapIcon} src={Musiswap} alt="" />
          <div className={classes.header}>
            <span className={classes.colour}>NFT</span> DROP ZONE
          </div>
        </div>
      </div>
      <div className={classes.nftReleases}>
        <div className={classes.header}>
          <div className={classes.backheader}>
            <span className={classes.colour}>NFT</span> DROP ZONE
          </div>
          <div className={classes.frontheader}>Featured Upcoming NFT Releases</div>
        </div>
        <ImageSlider assets={assets} />
      </div>
      <div className={classes.featuredNft}>
        <div className={classes.featuredTitle}>Featured NFTs</div>
        <div className={classes.nftWrapper}>
          {assets && assets.map((asset: any, index: number) => <Nft key={index} asset={asset} />)}
        </div>
      </div>
    </div>
  )
}

export default DropZone

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js';
import { ethers } from 'ethers';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from '../../assets/images/Collectible-image.png'
import DropsImage from '../../assets/images/drops-image.png'
import artistImage from '../../assets/images/artist-image.png'
import collabImage from '../../assets/images/collab-image.png'

const SLIDES = [Image, DropsImage]

const Collections = () => {
    const provider = new ethers.providers.JsonRpcProvider()
    const [collections, setCollections] = useState([]);
    const [assets, setAssets] = useState([]);
    const seaport = new OpenSeaPort(provider, {
      networkName: Network.Main
    })

    const conditionalInfinite = {
      dots: false,
      speed: 2000,
      slidesToShow: 3,
      infinite: SLIDES.length > 3,
      autoplay:true,
      slidesToScroll: 1,
      responsive: [
          {
              breakpoint: 991,
              settings: {
                  slidesToShow: 2,
                  infinite: SLIDES.length > 2
              }
          },
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 1,
                  infinite: SLIDES.length >= 1
              }
          }
      ]
  }

    console.log(collections)
    const fetchAsset = async () => {
        const asset = {
      tokenAddress: "0x06012c8cf97bead5deae237070f9587f8e7a266d", // CryptoKitties
      tokenId: "", // Token ID
      }
      const openSeaAsset = await seaport.api.getAsset(asset)
      console.log(openSeaAsset)
      }
      
      fetchAsset()
      
    useEffect(()=>{
        axios.get('https://api.opensea.io/api/v1/assets?owner=0x6e3cd6b1d5311afeb1357d69feecfd6f0ce54e18&order_direction=desc&offset=0&limit=8').then((res)=>{
            setAssets(res.data)
        })
      }, [])

      return (
        <div className="tw-w-auto tw-border tw-border-gray-200 tw-m-5 tw-px-20 tw-py-5 md:tw-w-full sm:tw-px-10  tw-h-auto">
            <div className="tw-flex tw-flex-row tw-justify-between">
              {/* header */}
              <p className="tw-font-bold ">Top Collections</p>
              <button className="tw-rounded-full tw-py-2 tw-px-4 tw-bg-white tw-text-green-300 tw-font-bold">View all</button>
            </div>
            <div className="tw-flex tw-flex-row tw-mt-8 tw-flex-nowrap tw-justify-between tw-w-full lg:tw-flex-col">
              {/* collections */}
              {SLIDES.map((each)=>(
                <div key={each} className="tw-flex tw-flex-row tw-relative tw-border tw-border-gray-200 tw-rounded-2xl tw-w-1/2 tw-h-28 lg:tw-w-full lg:tw-my-10 tw-mx-2">
                <div className="tw-absolute tw-bottom-10 tw-border-4 tw-border-green-400 tw-rounded-full">
                  {/* image */}
                  <img src={artistImage} className="tw-w-20 tw-h-20 tw-rounded-full" />
                </div>
                <div className="tw-absolute tw-right-5 tw-w-9/12">
                  {/* content */}
                  <div className="tw-flex tw-flex-row tw-justify-center tw-border-b tw-border-gray-200 tw-mt-4">
                    {/* top */}
                    <p className="tw-text-md tw-font-bold tw-mr-3">Music</p>
                    <p className="tw-text-sm tw-font-light">By lil nas</p>
                  </div>
                  <div className="tw-flex tw-flex-row tw-justify-around">
                    {/* bottom */}
                    <div className="tw-flex tw-flex-col" >
                      <p>
                        Floor Price
                      </p>
                      <p className="tw-text-green-400 tw-font-bold">
                        500$
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col" >
                      <p>
                        Volume
                      </p>
                      <p className="">
                        2400000$
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              ))}
            </div>
        </div>
      )

}

export default Collections;
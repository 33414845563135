import styles from './dropdown.module.css';
import VerifiedIcon from '../../../assets/music/verified-icon.png';
import Play from '../../../assets/music/play-button.svg';
import React, { useEffect, useState } from 'react';
import { formatTime } from '../utils';

const Dropdown = ({ song }) => {
  const { artist_name, artwork, description, release_date, views, exclusive, song_file } = song;
  const [duration, setDuration] = useState();

  useEffect(()=> {
    let audio = new Audio();
    audio.src = song_file;
    audio.onloadeddata = () => {
      setDuration(formatTime(audio.duration))
    }
  },[]);

  return (
    <div className={styles.container}>
      <img className={styles.image} src={artwork} alt="" />
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <h1 className={styles.title}>THIS IS MY DROP</h1>
          <p className={styles.artist}>
            <span>by {artist_name}</span>
            {
              exclusive === "true" && <img className={styles.verified} src={VerifiedIcon} alt="" />
            }
          </p>
        </div>
        <p className={styles.duration}>Rap <span className={styles.dot} /> Duration {duration}</p>
        <p className={styles.description}>{description}</p>
        <p className={styles.releaseDate}>Released {release_date}</p>
        <p className={styles.views}> <img src={Play} alt="" /> {views}</p>
      </div>
    </div>
  )
}

export default Dropdown;
import { ChainId, TokenAmount } from 'uniswap-polygon-sdk'
import React, { useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import LogoText from '../../assets/svg/wordmark_white.svg'
import LogoTextDark from '../../assets/svg/wordmark.svg'
import Logo from '../../assets/svg/logo.svg'
import  '../../styles.css'

import LogoDark from '../../assets/svg/logo_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances, useAggregateUniBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { TYPE, ExternalLink } from '../../theme'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faYoutube,
//   faFacebook,
//   faTwitter,
//   faDiscord,
//   faInstagram
// } from "@fortawesome/free-brands-svg-icons";
import { SocialIcon } from 'react-social-icons'

// import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faGithub } from '@fortawesome/free-brands-svg-icons';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'


// const element = <FontAwesomeIcon icon={faCoffee} />

const FooterFrame = styled.div`
  display: grid;
  background-color: #000;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-: row;
  width: 100%;
  bottom: 0;
  position: relative;
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`





const FooterRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const FooterLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`






const Title = styled.a`
  margin: auto;
  pointer-events: auto;
  justify-self: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 200;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    font-weight: 400;
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 200;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  return (
    // <FooterFrame>

      <div className="footerBar">
        <Title href=".">
          <UniIcon>
            <img width={'150px'} src={isDark ? LogoTextDark : LogoText} alt="MusiSWAP Text logo" />
          </UniIcon>
        </Title>
        <div className="socialIconsDiv">
            <SocialIcon bgColor="white"  url="https://discord.gg/AWrktKaqkr"/>
            <SocialIcon bgColor="white" url="https://www.twitter.com/musiswap"/>
            <SocialIcon bgColor="white" url="https://www.instagram.com/musiswap"/>
            <SocialIcon bgColor="white" url="https://www.linkedin.com/company/musiswap/"/>
            <SocialIcon bgColor="white" url="https://www.tiktok.com/@musiswap"/>
            <SocialIcon network="email"bgColor="white" url="https://forms.gle/9tqKWG2Y3TouTWGs7"/>
        </div>
                  {/* <FooterLinks>
          <StyledNavLink id={`stake-nav-link`} to={'https://twitter.com/musiswap'}>
            Twitter
          </StyledNavLink>
          <StyledNavLink id={`stake-nav-link`} to={'https://discord.gg/GGHCVSzAQg'}>
            Discord
          </StyledNavLink>
          <StyledNavLink id={`stake-nav-link`} to={'/vote'}>
            Instagram
          </StyledNavLink>
        </FooterLinks> */}
      </div>

    // </FooterFrame>
  )
}

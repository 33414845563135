import { CurrencyAmount, JSBI, Token, Trade } from 'uniswap-polygon-sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
import LandingPage from '../../assets/images/LandingPage_.png'
import PlainBanner from '../../assets/images/plainmusiswapbanner.svg'
import HowItWorks from '../../assets/images/HowItWorks.svg'
import ChartGrouping from '../../assets/images/chart-grouping.svg'
import Web3Diagram from '../../assets/images/web3diagram.svg'
import AppPreview from '../../assets/images/AppPreview.png'
import Evolution from '../../assets/images/Evolution.png'
import InfoCard from '../../components/InfoCard'
import Header1 from '../../assets/images/header.png'


import '../../styles.css'
import styles from './styles.module.css';

import Banner from '../../components/Banner'
import { WyvernSchemaName } from "opensea-js/lib/types"
import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader';
import wav from '../../assets/videos/sound-wav.mp4';
import musiswap from '../../assets/images/MUSISWAP.png';
import melody1 from '../../assets/images/melody1.png';
import melody2 from '../../assets/images/melody2.png';
import melody3 from '../../assets/images/melody3.png';
import InternetExploration from '../../components/InternetEvolution'

// This example provider won't let you make transactions, only read-only calls:


export interface Asset {
  // The asset's token ID, or null if ERC-20
  tokenId: string | null,
  // The asset's contract address
  tokenAddress: string,
  // The Wyvern schema name (defaults to "ERC721") for this asset
  schemaName?: WyvernSchemaName,
  // Optional for ENS names
  name?: string,
  // Optional for fungible items
  decimals?: number
}


export default function Landing({ scroll }: any) {

  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
        ? Version.v2
        : undefined

  const parsedAmounts = showWrap
    ? {
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedAmount
    }
    : {
      [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
      [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
    }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
                ? 'Swap w/o Send + recipient'
                : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  useEffect(() => {
    handleMouseMove()
  }, [scroll])

  const handleMouseMove = () => {
    let mel1: any = document.querySelector("#mel1");
    let mel2: any = document.querySelector("#mel2");
    mel2.style.transform = `translateY(${-scroll}%) translateX(${-scroll / 2}%)`
    mel1.style.transform = `translateY(${scroll}%) translateX(${scroll / 4}%) rotate(180deg)`
  }


  return (
    <>
      {/* <Header/> */}
      {/* <img src={Header}/> */}
      {/* <img className="fullFrame" src={LandingPage}/> */}
      {/* <Banner/> */}
      {/* <HeaderCarrousel LandingPage={LandingPage} /> */}
      {/* <img src='../../assets/images/header.png'></img> */}
      {/* <iframe loading="lazy" className="pitch"  src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAEnb5zmpAk&#x2F;view?embed">  </iframe> */}
      {/* <h1>MusiSwap</h1> */}
      {/* <h2>The only musician exchange</h2> */}
      {/* <div>Musiswap is the only musician exchange. Buy musician tokens, swap them, use them for exclusive content + merchandise, and engage with your artist's community</div> */}
      {/* <Header/> */}
      {/* <img className="fullFrame" src={Header}/> */}
      {/* <InfoCard/> */}
      {/* <img className="fullFrame" src={LandingPage}/> */}
      {/* <img className="fullFrame" src={PlainBanner}/> */}
      {/* <img className="fullFrame" src={HowItWorks}/> */}

      <div id="bg" onScroll={handleMouseMove} className={styles.bgContainer}>
        <video className={styles.bgWav} autoPlay muted loop>
          <source src={wav} type="video/mp4" />
        </video>
        <div className={styles.bgContent}>
          <div className={styles.logo}>
            <img className={styles.image} src={musiswap} alt="MusiSWAP logo" />
            <div className={styles.description}> Web3 + music</div>
          </div>
          <div className={styles.text}>
            A community of artists and fans building Web3 solutions for the music industry
          </div>
          <a className="launchLink" href="https://discord.gg/AWrktKaqkr" target="_blank" rel="noopener noreferrer" > <div className="launchButton">Join the Discord</div></a>

          <div className={styles.parallax}>
            <img id="mel1" className={styles.melody1} src={melody1} alt="MusiSWAP note" />
            <img id="mel2" className={styles.melody1} src={melody1} alt="MusiSWAP note" />
          </div>
          <img className={styles.melody2} src={melody2} alt="" />
        </div>
        <div className={styles.bgOverlay} />
      </div>

      {/* <div className="landingTextCard">
      <h1>First, First, First</h1>
        <p>
          <ul>
          <li>
            First ever musician exchange
          </li>
          <li>First to introduce music indexes</li>
          <li>First ever musician exchange</li>
          <li>Rewards for artist support & collaboration</li>
          <li>Fan based Decentralized organizations (DAOs) around artists</li>
          <li>chain agnostic social verification system</li>
          <li>Pay to play exclusive jukebox content</li>
          </ul>

        </p>
    </div> */}
      {/* <img className="fullFrame" src={ChartGrouping}/> */}
      <img className="almostFullFrame bounceImage" src={AppPreview} />

      {/* <img className="almostFullFrame" src={Evolution}/> */}
        <InternetExploration />
      {/* <Banner/> */}
      {/* <img src='../../assets/images/header.png'></img> */}
      {/* <iframe loading="lazy" className="pitch"  src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAEnb5zmpAk&#x2F;view?embed">  </iframe> */}
      {/* <h1>MusiSwap</h1> */}
      {/* <h2>The only musician exchange</h2> */}
      {/* <div>Musiswap is the only musician exchange. Buy musician tokens, swap them, use them for exclusive content + merchandise, and engage with your artist's community</div> */}
    </>
  )
}

import styles from './styles.module.css'
import React, { useEffect, useState } from 'react'
import Logo from '../../assets/svg/logo_2.svg'

const CommingSoon = (props: any) => {
  const { location, ignore } = props

  const [show, setShow] = useState(false)

  useEffect(() => {
    ignore.forEach((path: string) => {
      if (location !== path) {
        setShow(true)
      }
    })
  }, [location])

  useEffect(() => {
    let modal = document.querySelector('#show')
    modal?.addEventListener('animationend', function(this: HTMLElement, ev: Event) {
      setShow(false)
    })
  }, [])

  return (
    <div id="show" className={`${styles.container} ${show ? styles.show : null}`}>
      <div className={styles.wrapper}>
        <div className={styles.text}>Coming Soon</div>
        <img className={styles.logo} src={Logo} />
        <div onClick={() => setShow(false)} className={styles.button}>
          Close
        </div>
      </div>
    </div>
  )
}

export default CommingSoon

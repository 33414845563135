import Dropdown from '../dropdown/dropdown';
import styles from './song.module.css';
import { useState, useEffect } from 'react';
import VerifiedIcon from '../../../assets/music/verified-icon.png';
import React from 'react';

const Artist = ({ click, song, activeSong }) => {
  const [dropdown, setDropdown] = useState(false);

  const { artist_name, cost, artwork, exclusive } = song;

  const handleClick = () => {
    click(song);
    if (activeSong.id === song.id) {
      setDropdown(!dropdown)
    } else {
      setDropdown(false);
    }
  }

  useEffect(() => {
    if (activeSong.id === song.id) {
      setDropdown(!dropdown)
    } else {
      setDropdown(false);
    }
  }, [activeSong]);

  return (
    <div className={`${styles.wrapper} ${dropdown ? styles.active : styles.inactive}`}>
      <div
        onClick={handleClick}
        className={styles.container}
      >
        <div className={styles.innerWrapper}>
          <div className={styles.coinWrapper}>
            <img className={styles.coin} src={artwork} alt="" />
          </div>
          <div className={styles.name}>
            <div>{artist_name}</div>
            {
              exclusive === "true" && <img className={styles.verified} src={VerifiedIcon} alt="" />
            }
          </div>
        </div>
        <div className={styles.amount}>{cost} ${artist_name}</div>
      </div>
      <div className={styles.dropdownWrapper}>
        <Dropdown song={song} />
      </div>
    </div>
  )
}

export default Artist;
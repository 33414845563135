import classes from './styles.module.css'
import React from 'react'

const Nft = (props: any) => {
  const { asset } = props

  const { name, image_url, sell_orders, permalink } = asset

  let eth = null, usd = null, symbol = null;


  try {
    const sell_order = sell_orders[0];
    const {
      payment_token_contract: { eth_price, usd_price, image_url }
    } = sell_order
    eth = eth_price;
    usd = usd_price;
    symbol = image_url;
  } catch (error) {
    console.error(error)
  }

  return (
    <div className={classes.container}>
      <div className={classes.name}>{name}</div>
      <img className={classes.image} src={image_url} alt="" />
      {
        sell_orders ? (
          <>
            <div className={classes.priceTitle}>Current price</div>
            <div className={classes.price}>
              <img className={classes.coin} src={symbol} alt="" />
              <div className={classes.value}>{Number(eth)}</div>
              <div className={classes.rate}>${Number(usd)}</div>
            </div>
            <div className={classes.buttons}>
              <a href={permalink} target="_blank" rel="noreferrer">
                <div className={classes.buy}>Buy now</div>
              </a>
              <a href={permalink} target="_blank" rel="noreferrer">
                <div className={classes.offer}>Make offer</div>
              </a>
            </div>
          </>
        ) : <div className={classes.noSales}>No Sales</div>
      }

    </div>
  )
}

export default Nft

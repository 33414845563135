import styles from './preview.module.css';
import Play from '../../../assets/music/play-button.svg';
import Pause from '../../../assets/music/pause-button.svg';
import VerifiedIcon from '../../../assets/music/verified-icon.png';
import React, { useState, useEffect, useRef } from 'react';
import { formatTime } from '../utils';

const Preview = ({ setUploaded }: any) => {
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("00:00");
  const [play, setPlay] = useState(true);
  const [value, setValue] = useState("0");
  const [max, setMax] = useState("");

  const audioRef = useRef(null);

  let audio: any = audioRef.current;

  const handleClick = () => {
    if (audio.paused || audio.ended) {
      audio.play();
      setPlay(false)
    } else {
      audio.pause();
      setPlay(true)
    }

    audio.onended = () => {
      setPlay(true);
      audio.currentTime = 0;
    }

    audio.onemptied = () => {
      setPlay(true)
    }

    audio.ontimeupdate = () => {
      setCurrentTime(formatTime(audio.currentTime));
      setValue(audio.currentTime)
    }

    audio.onplay = () => {
      let duration = audio.duration === Infinity ? 0 : audio.duration;
      setDuration(formatTime(duration))
    }
  }

  const handleChange = (e: any) => {

    setValue(audio.currentTime = e.target.value)
  }

  // this code is valid when an audio file is available
  // useEffect(() => {
  //   audio.onloadeddata = (e: any) => {
  //     let duration = audio.duration === Infinity ? 0 : audio.duration;
  //     setDuration(formatTime(duration));
  //     setMax(duration);
  //     setValue(audio.currentTime)
  //   }
  // }, [])

  return (
    <div className={styles.container}>
      <div className={styles.player}>
        <img src={""} alt="" />
      </div>
      <div className={styles.control}>
        <div className={styles.playBtn}>
          {
            play
              ? <img onClick={handleClick} src={Play} alt="" />
              : <img onClick={handleClick} src={Pause} alt="" />
          }
        </div>
        <div className={styles.range}>
          <audio style={{ display: 'none' }} ref={audioRef} src={""}></audio>
          <input
            onChange={handleChange}
            value={value}
            type="range"
            min="0"
            max={max}
            className={styles.levelInner}
          />
          <div className={styles.duration}>{currentTime}/{duration}</div>
        </div>
      </div>
      <div className={styles.description}>
        <h1>{"song_title"}</h1>
        <h1>({"description"})</h1>
        <p>
          by {"artist name"}
          {
             true && <img className={styles.verified} src={VerifiedIcon} alt="" />
          }
        </p>
      </div>
      <div className={styles.upload}>
        <button onClick={()=> setUploaded(null)} className={styles.btn}>Back</button>
      </div>
    </div>
  )
}

export default Preview;
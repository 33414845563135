import styles from './search.module.css';
import SearchIcon from '../../../assets/music/search-icon.svg';
import React, { useState } from 'react';

const Search = ({ onSearch }) => {
  const [input, setInput] = useState(false);
  const [value, setValue] = useState("");

  const handleClick = () => {
    setInput(!input)
    setValue("")
  }

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    onSearch(value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <input
          className={`${input && styles.searchbar}`}
          onChange={handleChange}
          value={value}
          type="text"
          placeholder="search"
        />
      </div>
      <img onClick={handleClick} className={styles.searchIcon} src={SearchIcon} alt="" />
    </div>
  )
}

export default Search;